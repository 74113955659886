import { MessageCampaignList } from '../components/MessageCampaign';

function MessageCampaignsListRoute() {
  return <MessageCampaignList />;
}

export const Component = MessageCampaignsListRoute;

export function clientLoader() {
  return null;
}
